<template>
  <div>
    <!-- Modal -->
    <b-modal
      id="data_lake"
      title="Data lake"
      size="md"
      centered
      @hidden="closeModal"
    >
      <div class="form-group">
        <input type="file" ref="fileInput" />
      </div>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="closeModal"> Cancelar </b-button>
        <b-button variant="primary" @click="uploadFile">
          Subir archivo
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
    this.openModal();
  },
  methods: {
    openModal() {
      this.$bvModal.show("data_lake");
    },
    closeModal() {
      this.$bvModal.hide("data_lake");
      this.$nextTick(() => {
        this.$router.push("/dashboard");
      });
    },
    uploadFile() {
      const file = this.$refs.fileInput.files[0];
      console.log(file);
    },
  },
};
</script>
<style></style>
